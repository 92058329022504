import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../components/Layout"
import SignContainer from "../containers/SignContainer"
import PaymentsContainer from "../containers/PaymentsContainer"
import axios from "axios"
import { setSignUpPopup } from "../store/modules/popup"
import jwt_decode from "jwt-decode"
import { localStorgeGet } from "../utils/util"

//구글 로그인 콜백 페이지
const authLoginGoogle = props => {
  const { postSignIn, postSignupDatas, setAuthEmailSeq } = props
  const dispatch = useDispatch()
  const signUpPopup = useSelector(state => state.popup.signUpPopup)
  const [sign, setSign] = useState(false)
  const url = typeof window !== "undefined" ? window.location.hash : ""
  const getNaverToken = () => {
    if (url == "" || url == undefined || url.includes("error")) {
      popupFn("로그인 실패")
      navigate("/exploring")
      return false
    }
  }
  const onLogout = () => {
    // if (window.gapi) {
    //   const auth2 = window.gapi.auth2.getAuthInstance()
    //   if (auth2 !== null) {
    //     auth2
    //       .signOut()
    //       .then(auth2.disconnect().then(() => onLogoutSuccess()))
    //       .catch(e => console.log(e))
    //   }
    // }
  }
  useEffect(() => {
    if (!signUpPopup && sign) {
      let studySeq = localStorgeGet("inviteStudy")
      if (studySeq) {
        navigate("/exploring" + studySeq)
        localStorage.removeItem("inviteStudy")
      } else {
        navigate("/exploring")
      }
    }
  }, [signUpPopup])
  useEffect(async () => {
    getNaverToken()
    dispatch(setSignUpPopup(false))
    if (props.location.hash) {
      let orderInfo = props.location.hash
      let reqTemp = orderInfo.split("&")
      let googleToken = ""
      for (let i of reqTemp) {
        if (i.indexOf("id_token=") != -1) googleToken = i.split("id_token=")[1]
      }
      const decoded = jwt_decode(googleToken)
      const param = {
        id: decoded.sub,
        email: decoded.email || "",
        access_token: googleToken,
      }
      let seq = ""
      let token = ""
      let deviceNumber = ""
      let test = navigator.userAgent
      let arr = test.split(" ")
      for (let i of arr) {
        if (i.indexOf("token_") != -1) {
          token = i.split("oken_")[1]
        } else if (i.indexOf("deviceNumber") != -1) {
          deviceNumber = i.split("Number")[1]
        }
      }
      let studySeq = localStorgeGet("inviteStudy")
      if (studySeq) {
        let tempArr = studySeq.split("&")
        for (let i of tempArr) {
          if (i.indexOf("studySeq") != -1) {
            seq = i.split("=")[1]
          }
        }
      }
      if (!googleToken) return false
      if (localStorgeGet("googleType") == "login") {
        let temp = {
          signType: "GOOGLE",
          signId: param.id,
          signToken: param.access_token,
          email: param.email,
          password: "",
          fcmToken: token,
          deviceNumber: deviceNumber,
          studySeq: seq,
        }

        let result = await postSignIn(temp)
        if (result) {
          localStorage.removeItem("googleType")
          if (studySeq) {
            localStorage.removeItem("inviteStudy")
            navigate("/exploring" + studySeq)
          } else {
            navigate("/exploring")
          }
        } else {
          sessionStorage.setItem("gtk", orderInfo)
          navigate("/exploring")

          localStorage.removeItem("googleType")
        }
      } else if (localStorgeGet("googleType") == "join") {
        let temp = {
          name: "",
          nickname: "",
          email: param.email,
          password: "",
          signType: "GOOGLE",
          signId: param.id,
          signToken: param.access_token,
          callback: "",
          studySeq: seq,
        }
        let result = await postSignupDatas(temp)
        if (result) {
          setSign(true)
          setAuthEmailSeq({ authEmailSeq: "", customerAppSignDataSeq: result.customerAppSignDataSeq })
          dispatch(setSignUpPopup(true))
          localStorage.removeItem("googleType")
        } else {
          onLogout()
          sessionStorage.setItem("gtk", orderInfo)
          localStorage.removeItem("googleType")

          navigate("/exploring")
        }
      }
    } else {
      sessionStorage.setItem("gtk", orderInfo)
      localStorage.removeItem("googleType")
      navigate("/exploring")
    }
  }, [])
  return (
    <Layout>
      <div></div>
    </Layout>
  )
}

export default SignContainer(authLoginGoogle)
